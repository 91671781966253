<template>
  <setup-table
    :title="`Third Party Payment Option`"
    :items="thirdPartyPayments"
    :headers="headers"
    :loading="isLoading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <third-party-payment-options-form
        ref="ThirdPartyPaymentOptionsForm"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
      ></third-party-payment-options-form>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SetupTable from './SetupTable.vue';
import ThirdPartyPaymentOptionsForm from './ThirdPartyPaymentOptionsForm.vue';

export default {
  name: 'ThirdPartyPaymentOptionsTable',
  inject: ['eventHub'],
  components: { SetupTable, ThirdPartyPaymentOptionsForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
    };
  },
  mounted() {
    this.fetchItems();
  },
  computed: {
    ...mapGetters('thirdPartyPayment', ['thirdPartyPayments','isLoading']),
    headers() {
      return [
        { text: 'Third Party Payment Label', value: 'name' },
      ];
    },
  },
  methods: {
    ...mapActions('thirdPartyPayment', ['getThirdPartyPayments','saveThirdPartyPayment','deleteThirdPartyPayments']),
    async fetchItems() {
      await this.getThirdPartyPayments({ forTable: true });
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0, name: '' };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const response = await this.saveThirdPartyPayment(item);
        if (response && response.id) {
          this.$myalert.success('Third Party Payment Option saved', true);
          this.editedItem = { ...item, id: response.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Third Party Payment Options' : 'this Third Party Payment Option'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const response = await this.deleteThirdPartyPayments(items.map((e) => e.id));
        if (response.success) {
          this.$myalert.success(`${items.length > 1 ? 'Third Party Payment Options' : 'Third Party Payment Option'} deleted`, true);
          this.showForm = false;
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
